<template>
  <v-container fluid>
    <v-col
      cols="12"
      v-if="loading && !company">
      <div class="text-center" >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>
    </v-col>
    <template v-else>
      <v-alert
        :value="company.deactivated_at"
        type="error"
        class="mb-0"
      >
        Dieser Partner wurde deaktiviert. Er wird Kunden nicht mehr angezeigt.
      </v-alert>
      <v-tabs slider-color="primary">
        <v-tab
          :disabled="tab !== 'Allgemein' && !company.id"
          :key="tab"
          ripple
          :to="getSubpageRoute(tabKey)"
          v-for="(tab, tabKey) in tabs">
          {{ tab }}
        </v-tab>
      </v-tabs>
      <router-view
        @update="loadCompany"
        :company="company"
        :holidays="holidays"
        :superadmin="superadmin" />
    </template>
  </v-container>
</template>

<script>
import CompaniesAPI from '@/api/companies'

export default {
  data() {
    return {
      loading: false,
      company: null,
      holidays: null,
      superadmin: false,
    }
  },

  computed: {
    tabs() {
      const tabs = {
        general: 'Allgemein',
        contactPerson: 'Ansprechpartner',
        sloganAndLogo: 'Slogan & Logo',
        rentProducts: 'Miethilfsmittel',
        deliveryArea: 'Liefergebiete',
        holiday: 'Feiertage',
        branchOffices: 'Filialen',
      }
      if (!this.company.partner_mh) {
        delete tabs.deliveryArea
        delete tabs.rentProducts
      }
      return tabs
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.company) {
          this.$store.commit('app/setTitle', `Partner - ${this.company.name}`)
        }
        this.loadCompany()
      },
      immediate: true,
    },
  },
  methods: {
    getSubpageRoute(pageKey) {
      return {
        name: `company.${pageKey}`,
        params: {
          id: this.company.id,
        },
      }
    },
    loadCompany($event) {
      const id = $event || this.$route.params.id
      if (id === 'new') {
        this.company = {}
        this.superadmin = true
        this.$store.commit('app/setTitle', 'Partner anlegen')
        return
      }

      this.loading = true
      CompaniesAPI.getCompany(id).then((response) => {
        this.company = response.company
        this.holidays = response.holidays
        this.superadmin = response.superadmin
        this.$store.commit('app/setTitle', `Partner - ${this.company.name}`)
        this.$store.commit('company/setCompany', response.company)
        this.loading = false
      })
    },
  },
}
</script>
